(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/socials.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/socials.js');
"use strict";


const Icon = svs.ui.ReactIcon;
const copyDialog = () => new svs.ui.dialog.Confirm({
  branding: svs.ui.dialog.branding.TUR,
  type: svs.ui.dialog.type.INFO,
  icon: 'thumbs-up',
  title: 'Länken kopierad',
  message: [{
    type: svs.ui.dialog.message.TEXT,
    text: 'Klistra in den i ett SMS, e-post, chatt eller valfritt meddelande.'
  }],
  actions: [{
    title: 'Okej'
  }]
});
const Socials = () => {
  const uri = encodeURIComponent(window.location.href);
  const logClick = linkName => svs.supportern.logger.info("User clicked share link: ".concat(linkName));
  const handleCopyLink = event => {
    var _navigator;
    event.preventDefault();
    logClick('copyToClipboard');
    (_navigator = navigator) === null || _navigator === void 0 || (_navigator = _navigator.clipboard) === null || _navigator === void 0 || _navigator.writeText(window.location.href);
    svs.ui.dialog.api.add(copyDialog());
  };
  return React.createElement("div", {
    className: "f-center padding-top-2 padding-bottom-2 u-border-top-2 u-border-bottom-2 border-grey-300 margin-top-3 margin-bottom-3"
  }, React.createElement("span", {
    className: "block f-550"
  }, "Dela lagsida"), React.createElement("div", {
    className: "share-links margin-top-1"
  }, React.createElement("a", {
    href: "https://www.facebook.com/sharer/sharer.php?u=".concat(uri),
    onClick: () => logClick('facebook'),
    rel: "nofollow noopener noreferrer",
    target: "_blank"
  }, React.createElement(Icon, {
    icon: "facebook"
  })), React.createElement("a", {
    href: "https://twitter.com/intent/tweet?url=".concat(uri),
    onClick: () => logClick('twitter'),
    rel: "nofollow noopener noreferrer",
    target: "_blank"
  }, React.createElement(Icon, {
    icon: "twitter"
  })), React.createElement("a", {
    href: "#",
    onClick: handleCopyLink
  }, React.createElement(Icon, {
    icon: "link"
  }))));
};
setGlobal('svs.supportern.components.Socials', Socials);

 })(window);