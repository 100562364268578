(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/my-tickets/settled.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/my-tickets/settled.js');
"use strict";


const {
  useAtom,
  Provider
} = jotai;
const {
  ticketsAtom,
  paidTicketsAtom
} = svs.supportern.atoms;
const {
  SettledList
} = svs.supportern.components;
const {
  useEffect
} = React;
let hasShownDialog = false;
const createClosedGameDialog = ticketId => {
  const dialog = new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    icon: 'notifications',
    area: svs.ui.dialog.area.TOASTER,
    title: 'Dags för dragning!',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: 'Du har orättade spel.'
    }],
    actions: [{
      title: 'Okej',
      callback: () => {
        var _document$getElementB, _document$querySelect;
        dialog.close();
        const {
          top = 0
        } = ((_document$getElementB = document.getElementById("ticketId_".concat(ticketId))) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.getBoundingClientRect()) || {};
        const topContent = ((_document$querySelect = document.querySelector('.js-fixed-top-content')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.getBoundingClientRect()) || {
          height: 0
        };
        const offsetTop = top + window.scrollY - topContent.height;
        window.scrollTo({
          behavior: 'smooth',
          top: offsetTop
        });
      }
    }]
  });
  return dialog;
};
setGlobal('svs.supportern.routes', {
  Settled: () => {
    const [tickets] = useAtom(paidTicketsAtom);
    useEffect(() => {
      if (tickets && tickets.length) {
        const foundTicket = tickets.find(ticket => ticket.draw.status === 'CLOSED' && !ticket.resultsPresented);
        if (foundTicket && !hasShownDialog) {
          const closedGameDialog = createClosedGameDialog(foundTicket.id);
          svs.ui.dialog.api.add(closedGameDialog);
          hasShownDialog = true;
        }
      }
    }, []);
    return React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("h2", {
      className: "h2 margin-bottom-1 margin-top-3 padding-bottom f-medium"
    }, "Avgjorda spel"), tickets.length ? React.createElement(Provider, {
      initialValues: [[ticketsAtom, tickets]]
    }, React.createElement(SettledList, null)) : React.createElement(React.Fragment, null, React.createElement("p", null, "Du har inga avgjorda spel.")));
  }
});

 })(window);