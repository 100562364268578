(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/settled-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/settled-list.js');
"use strict";


const {
  useAtomValue,
  Provider
} = jotai;
const {
  ticketAtom,
  ticketsAtom
} = svs.supportern.atoms;
const {
  TicketListItem
} = svs.supportern.components.composites;
const {
  TICKET_PRIZE_CHECKED
} = svs.supportern.utils.rafflePaths;
const {
  onExtendEndedGame
} = svs.supportern.utils.tracking;
const handleCancelledTickets = ticketArray => {
  if (!(ticketArray !== null && ticketArray !== void 0 && ticketArray.length)) return;
  svs.supportern.logger.info("User has ".concat(ticketArray.length, " cancelled tickets. Marking as seen."));
  svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
    branding: svs.ui.dialog.branding.TUR,
    type: svs.ui.dialog.type.INFO,
    icon: 'exclamation-sign',
    title: 'Hoppsan!',
    message: [{
      type: svs.ui.dialog.message.TEXT,
      text: 'Ett spel har tyvärr blivit inställt. Du hittar det under "Mina Supporternspel".'
    }],
    actions: [{
      title: 'Okej'
    }]
  }));
  for (const ticket of ticketArray) {
    svs.supportern.utils.apiController({
      path: TICKET_PRIZE_CHECKED.replace(':id', ticket.id),
      options: {
        method: 'POST'
      }
    });
  }
};
const SettledList = () => {
  const tickets = useAtomValue(ticketsAtom);
  const cancelledTickets = tickets.filter(_ref => {
    let {
      draw,
      resultsPresented
    } = _ref;
    return draw.status === 'CANCELLED' && !resultsPresented;
  });
  handleCancelledTickets(cancelledTickets);
  return React.createElement(React.Fragment, null, tickets.map(ticket => React.createElement(Provider, {
    initialValues: [[ticketAtom, ticket]],
    key: ticket.draw.id
  }, React.createElement(TicketListItem, {
    onClick: onExtendEndedGame
  }))));
};
setGlobal('svs.supportern.components.SettledList', SettledList);

 })(window);